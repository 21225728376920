<template>
  <v-container fluid class="px-12">
    <v-row class="my-2">
      <!-- Search Bar -->
      <v-expansion-panels multiple v-model="expanded_search_panel">
        <v-expansion-panel>
          <v-expansion-panel-header>Export Report</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-card flat>
              <v-row align="center">
                <!-- Date Picker -->
                <v-col cols="6" class="py-1">
                  <v-menu ref="menu" v-model="date_picker.menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="290px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        dense
                        color="black"
                        hide-details
                        outlined
                        v-model="date_rage"
                        label="Search Range"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker color="#2fcfa8" landscape ref="picker" v-model="date_picker.date" range></v-date-picker>
                  </v-menu>
                </v-col>

                <!-- Report Type Selector -->
                <v-col cols="12" sm="6" class="py-2">
                  <v-select outlined hide-details dense label="Report Type" v-model="report_type" :items="report_type_list" item-text="title" item-value="value"></v-select>
                </v-col>

                <!-- Queue Selector -->
                <v-slide-x-reverse-transition mode="out-in">
                  <v-col cols="12" class="py-2" v-show="is_show_queue_selector">
                    <v-select outlined hide-details dense multiple label="Select Queues" v-model="queues" :items="queue_list" item-text="service_type" item-value="service_type_id"></v-select>
                  </v-col>
                </v-slide-x-reverse-transition>

                <!-- Staff Selector -->
                <v-slide-x-reverse-transition mode="out-in">
                  <v-col cols="12" class="py-2" v-show="is_show_staff_selector">
                    <v-select outlined hide-details dense multiple label="Select Staffs" v-model="staffs" :items="staff_list" :item-text="fullname" item-value="user_id"></v-select>
                  </v-col>
                </v-slide-x-reverse-transition>
              </v-row>

              <!-- Action Buttons -->
              <v-row>
                <v-col cols="12" class="py-2">
                  <v-btn block @click="startExport" :disabled="disable_export_btn">
                    <v-icon left small>fas fa-file-export</v-icon>
                    Export
                  </v-btn>
                </v-col>
              </v-row>
            </v-card>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>

    <!-- Report List  -->
    <v-row class="my-2">
      <v-expansion-panels multiple v-model="expanded_list_panel">
        <v-expansion-panel>
          <v-expansion-panel-header>Report List</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-card flat width="100%">
              <v-row></v-row>
              <v-row>
                <v-col cols="12">
                  <v-data-table hide-default-footer :headers="headers" :items="records">
                    <!-- Index -->
                    <template v-slot:[`item.index`]="{ item }">
                      {{ records.indexOf(item) + 1 }}
                    </template>

                    <!-- Action -->
                    <template v-slot:[`item.action`]="{ item }">
                      <v-btn @click="download(item)" :disabled="item.status === 'Processing'" x-small depressed color="success">
                        <v-icon x-small>fas fa-download</v-icon>
                      </v-btn>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-card>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
  </v-container>
</template>

<script>
import axios from '@/apis/axios'
import url from '@/apis/urls'

import { mapActions, mapMutations, mapState } from 'vuex'

export default {
  name: 'Reports',
  data() {
    return {
      expanded_search_panel: [0],
      expanded_list_panel: [0],
      date_picker: {
        menu: null,
        date: [this.$moment().format('YYYY-MM-DD'), this.$moment().format('YYYY-MM-DD')]
      },
      report_type: null,
      queues: [],
      staffs: [],
      is_show_queue_selector: false,
      is_show_staff_selector: false,
      search_params: null
    }
  },
  methods: {
    ...mapMutations(['SET_SNACKBAR']),
    ...mapActions(['searchReport', 'exportReport', 'downloadReport']),
    fullname: item => item.firstname + ' ' + item.lastname,
    getParamsFormat() {
      if (!this.date_picker.date) {
        this.SET_SNACKBAR({ status: true, color: 'error', text: 'Please Select Date Range', icon: 'close', timeout: '2000' })
      } else if (this.date_picker.date.length !== 2) {
        this.SET_SNACKBAR({ status: true, color: 'error', text: 'Please Select Two Days', icon: 'close', timeout: '2000' })
      } else if (this.report_type === 'queue_summary_report' && !this.queues.length) {
        this.SET_SNACKBAR({ status: true, color: 'error', text: 'Please Select Queues', icon: 'close', timeout: '2000' })
      } else if (this.report_type === 'staff_summary_report' && !this.staffs.length) {
        this.SET_SNACKBAR({ status: true, color: 'error', text: 'Please Select Staffs', icon: 'close', timeout: '2000' })
      } else {
        const params = {
          from_date: this.date_picker.date[0],
          to_date: this.date_picker.date[1],
          report_type: this.report_type
        }

        if (this.report_type === 'queue_summary_report') {
          if (this.queues.includes(null)) {
            const service_type = []
            this.general.queues.forEach(queue => {
              if (queue.service_type_id) {
                service_type.push(queue.service_type_id)
              }
            })
            params.service_types = service_type
          } else {
            params.service_types = this.queues
          }
        } else if (this.report_type === 'staff_summary_report') {
          if (this.staffs.includes(null)) {
            const users = []
            this.general.users.forEach(user => {
              if (user.user_id) {
                users.push(user.user_id)
              }
            })
            params.users = users
          } else {
            params.users = this.staffs
          }
        }
        return params
      }
      return false
    },
    search() {
      setTimeout(() => {
        if (this.disable_export_btn) {
          this.search()
          this.searchReport(this.search_params)
        }
      }, 5000)
    },
    startExport() {
      const params = this.getParamsFormat()
      if (params) {
        this.exportReport({ params: params })
        this.search_params = { from_date: params.from_date, to_date: params.to_date, report_type: params.report_type }
        this.search()
      }
    },
    download({ filename }) {
      this.SET_SNACKBAR({ status: true, color: 'loading', text: 'Downloading...', loading: true })
      const params = {
        report_type: this.report_type,
        filename: filename
      }
      axios
        .get(`${url.reports}/download?${new URLSearchParams(params).toString()}`, { responseType: 'blob' })
        .then(response => {
          this.SET_SNACKBAR({ status: true, color: 'success', text: 'Export request received, generating report...', icon: 'check', timeout: '2000' })
          const blob = new Blob([response.data], { type: 'text/csv' })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = filename
          link.click()
          URL.revokeObjectURL(link.href)
        })
        .catch(() => this.SET_SNACKBAR({ status: true, color: 'error', text: 'Cannot download, please try again later.', icon: 'close', timeout: '2000' }))
    }
  },
  computed: {
    ...mapState(['general', 'reports']),
    disable_export_btn: {
      get() {
        return this.reports.disable_export_btn
      },
      set(payload) {
        this.$store.commit('SET_REPORTS_DISABLE_EXPORT_BTN', payload)
      }
    },
    headers() {
      return this.reports.datatable.headers
    },
    records() {
      return this.reports.datatable.items
    },
    report_type_list() {
      return this.reports.report_type_list
    },
    date_rage() {
      if (this.date_picker.date) return this.date_picker.date.join(' ~ ')
      else {
        return null
      }
    },
    queue_list() {
      let queue_list = this.general.queues
      queue_list.unshift({ service_type_id: null, service_type: 'All Queues' })
      return queue_list
    },
    staff_list() {
      return this.general.users
    }
  },
  watch: {
    queues() {
      if (this.queues.length > 1 && this.queues.includes(null)) {
        this.queues = [null]
      }
    },
    staffs() {
      if (this.staffs.length > 1 && this.staffs.includes(null)) {
        this.staffs = [null]
      }
    },
    'date_picker.menu': {
      immediate: true,
      handler() {
        if (this.date_picker.menu) {
          this.date_picker.date = null
        }
      }
    },
    'date_picker.date': {
      immediate: true,
      handler() {
        // Auto close the date picker if user has selected two date
        if (this.date_picker.date && this.date_picker.date.length === 2) {
          this.date_picker.menu = false
        }
      }
    },
    report_type() {
      this.queues = []
      this.staffs = []
      this.disable_export_btn = this.report_type ? false : true
      this.is_show_queue_selector = this.report_type === 'queue_summary_report' ? true : false
      this.is_show_staff_selector = this.report_type === 'staff_summary_report' ? true : false
    }
  }
}
</script>
